.pk-slider {
  height: 600px;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 767px) {
    .pk-slider {
      height: 450px; } }
  @media screen and (max-width: 575px) {
    .pk-slider {
      height: 300px; } }
  .pk-slider .slider .slick-slider {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text; }
  .pk-slider .slider-list {
    display: none; }
  .pk-slider .slider-item {
    outline: 0;
    border: none;
    height: 600px;
    opacity: 0.6;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important; }
    @media screen and (max-width: 767px) {
      .pk-slider .slider-item {
        height: 450px; } }
    @media screen and (max-width: 575px) {
      .pk-slider .slider-item {
        height: 300px; } }
  .pk-slider .slider-layer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 5px;
    text-align: center;
    width: 100%; }
    .pk-slider .slider-layer img {
      margin: 0 auto;
      position: relative;
      z-index: 2;
      height: 380px;
      max-width: 100%;
      margin-bottom: 25px; }
      @media screen and (max-width: 767px) {
        .pk-slider .slider-layer img {
          height: 280px; } }
      @media screen and (max-width: 575px) {
        .pk-slider .slider-layer img {
          height: 100px; } }
  .pk-slider .slider-covid {
    position: absolute;
    left: -5px;
    top: -5px; }
  .pk-slider .facebook {
    background: #395692;
    height: 150px;
    width: 45px;
    margin-left: -5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s; }
    @media screen and (max-width: 767px) {
      .pk-slider .facebook {
        height: 50px; } }
    @media screen and (mim-width: 768px) {
      .pk-slider .facebook:hover {
        margin-left: 0px;
        background: #36528b; } }
    .pk-slider .facebook .wrapper {
      width: 100%;
      height: 100%;
      position: relative; }
      .pk-slider .facebook .wrapper i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
        @media screen and (min-width: 768px) {
          .pk-slider .facebook .wrapper i {
            display: none; } }
        .pk-slider .facebook .wrapper i:before {
          color: white;
          font-size: 20px; }
      .pk-slider .facebook .wrapper img {
        position: absolute;
        width: 120px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg); }
        @media screen and (max-width: 767px) {
          .pk-slider .facebook .wrapper img {
            display: none; } }

.pk-welcome {
  padding-top: 80px; }
  .pk-welcome p {
    text-align: center;
    margin-bottom: 0px;
    font-weight: 300;
    font-size: calc(16px + 4 * ( (100vw - 320px) / 1600)); }
    @media screen and (max-width: 320px) {
      .pk-welcome p {
        font-size: 16px; } }
    @media screen and (min-width: 1920px) {
      .pk-welcome p {
        font-size: 20px; } }
  .pk-welcome h4 {
    margin: 35px 0px 15px 0px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700; }
  .pk-welcome .equipments-list {
    padding-left: 0px;
    margin-bottom: 0px;
    justify-content: center; }
    @media screen and (max-width: 767px) {
      .pk-welcome .equipments-list {
        margin: 0px -5px; } }
  .pk-welcome .equipments-item {
    text-align: center;
    margin-top: 25px;
    background: white;
    padding: 15px 5px;
    border-radius: 5px;
    height: 135px;
    box-shadow: 0 2px 14px 5px rgba(20, 20, 20, 0.05); }
    .pk-welcome .equipments-item img {
      height: 45px; }
    .pk-welcome .equipments-item p {
      font-weight: 300;
      font-size: calc(14px + 2 * ( (100vw - 320px) / 1600));
      margin-top: 10px;
      text-align: center;
      margin-bottom: 0px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media screen and (max-width: 320px) {
        .pk-welcome .equipments-item p {
          font-size: 14px; } }
      @media screen and (min-width: 1920px) {
        .pk-welcome .equipments-item p {
          font-size: 16px; } }

.pk-promotion {
  position: relative; }
  .pk-promotion .wrapper {
    padding: 50px 20px;
    background: #383838;
    color: white;
    position: relative;
    overflow: hidden; }
    .pk-promotion .wrapper .after, .pk-promotion .wrapper .before {
      position: absolute;
      opacity: 0.15; }
      @media screen and (max-width: 767px) {
        .pk-promotion .wrapper .after, .pk-promotion .wrapper .before {
          display: none; } }
    .pk-promotion .wrapper .after {
      right: 15px;
      transform: rotate(18deg);
      bottom: -50px; }
    .pk-promotion .wrapper .before {
      left: 20px;
      bottom: -55px;
      transform: rotate(-18deg); }
    .pk-promotion .wrapper .content {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      position: relative;
      z-index: 1; }
      .pk-promotion .wrapper .content h2 {
        margin-bottom: 15px;
        font-weight: 700;
        text-align: center; }

.pk-weather {
  position: relative;
  overflow: hidden; }
  .pk-weather .before {
    position: absolute;
    width: 100%;
    right: -450px;
    top: -50px;
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .pk-weather .before {
        display: none; } }
  .pk-weather .wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed; }
    .pk-weather .wrapper .content {
      background: rgba(56, 56, 56, 0.8);
      box-shadow: 0 2px 14px 5px rgba(20, 20, 20, 0.05);
      border-radius: 5px;
      text-align: center;
      padding: 20px;
      color: white;
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 767px) {
        .pk-weather .wrapper .content {
          margin-top: 50px;
          border-radius: 0px; } }
      .pk-weather .wrapper .content p {
        font-weight: 300;
        font-size: calc(12px + 2 * ( (100vw - 320px) / 1600));
        font-style: italic; }
        @media screen and (max-width: 320px) {
          .pk-weather .wrapper .content p {
            font-size: 12px; } }
        @media screen and (min-width: 1920px) {
          .pk-weather .wrapper .content p {
            font-size: 14px; } }
    .pk-weather .wrapper .weather-box {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
      position: relative;
      z-index: 2;
      padding-top: 20px !important;
      background: #383838;
      box-shadow: 0 2px 14px 5px rgba(20, 20, 20, 0.05);
      border-radius: 5px; }
      @media screen and (max-width: 399px) {
        .pk-weather .wrapper .weather-box {
          padding: 0px !important; } }
      @media screen and (max-width: 399px) {
        .pk-weather .wrapper .weather-box #inpl-weather-main {
          transform: scale(0.8); } }
      .pk-weather .wrapper .weather-box .before {
        top: 0px;
        left: 0px;
        right: 0px;
        height: 7px;
        background: #FFCC00;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: absolute;
        display: block; }

.pk-images .images-list {
  height: 450px;
  margin: 5px;
  position: relative; }
  .pk-images .images-list .before {
    background: white;
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 2px 14px 5px rgba(20, 20, 20, 0.05);
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    height: 100px;
    width: 100px; }
  .pk-images .images-list .after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%) scale(0.25); }
  .pk-images .images-list-item {
    padding: 5px; }
  .pk-images .images-list .image-wrapper {
    overflow: hidden;
    height: 100%;
    border-radius: 5px; }
  .pk-images .images-list .image {
    height: 100%;
    background-size: cover;
    transition: all .2s ease-in-out;
    background-position: center; }
    .pk-images .images-list .image:hover {
      transform: rotate(4deg) scale(1.3); }

.pk-movie {
  height: 550px !important;
  position: relative;
  display: none; }
  .pk-movie .content {
    width: 250px;
    height: 155px;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
    transform: rotate(10deg);
    padding: 5px 20px;
    position: absolute;
    right: -10px;
    bottom: -40px;
    z-index: 1; }
    .pk-movie .content:before {
      content: "";
      position: absolute;
      top: -50px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 225px solid transparent;
      border-right: 50px solid transparent;
      border-bottom: 50px solid rgba(255, 255, 255, 0.8); }
    .pk-movie .content:after {
      content: "";
      position: absolute;
      bottom: -25px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      border-top: 25px solid rgba(255, 255, 255, 0.8); }
    .pk-movie .content img {
      margin-top: 20px;
      height: 50px;
      transform: rotate(-10deg); }
  .pk-movie video {
    width: 100%;
    height: 100%; }

.hotresChooser {
  background: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  padding: 15px;
  border-radius: 3px;
  max-width: 800px;
  margin: auto;
  height: 100%; }
  .hotresChooser div {
    float: left;
    margin: 0;
    padding: 0;
    width: 33%;
    box-sizing: border-box;
    padding: 4px; }
    .hotresChooser div input {
      font-weight: bold;
      border: 1px solid rgba(255, 255, 255, 0.4);
      background-image: none;
      background-color: transparent;
      box-shadow: none;
      cursor: pointer;
      color: #fff;
      font-size: 13px;
      text-align: center;
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
      border-radius: 3px;
      transition: all 0.1s ease-in-out;
      color: #231f20 !important;
      background: white !important; }
    .hotresChooser div button {
      border-radius: 3px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      background: #fff;
      box-shadow: none;
      cursor: pointer;
      color: #303030;
      font-size: 13px;
      text-align: center;
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      background: #FFCC00 !important; }
      .hotresChooser div button:hover {
        color: black !important; }
